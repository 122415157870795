import React, { useContext } from "react";
import "./dashboard.css";
import EventRow from "../Events/EventRow";
import { useEvents } from "../../hooks/useEvents";
import EventFilter from "../Events/EventFilter";
import backgroundImg from "../../images/bg.jpg";
import EventLink from "../Events/EventLink";
// import AreYouAnEventOrganizer from "../Events/AreYouAnEventOrganizer";
import EventRowSkeleton from "../Events/EventRowSkeleton";
import Carousel from "../../components/Carousel";
import SearchedEvents from "../Events/SearchedEvents";
import { SearchContext } from "../../components/context/useSearch";
import EventCard from "../Events/EventCard";
// import EventsList from "../Events/EventsList";
import CardSkeleton from "../Events/CardSkeleton";

import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet";

function Dashboard() {
  const {
    popularEvents,
    upcomingEvents,
    // weekendEvents,
    // featuredEvents,
    musicEvents,
    isLoading: eventsLoading,
    allEvents,
  } = useEvents();

  const {
    searchedEvents,
    search,
    debouncedQuery,
    handleSearch,
    handleClear,
    isLoading: searchLoading,
  } = useContext(SearchContext);
  

  return (
    <>
      <Helmet>
        <title>Congress Rental | </title>
      </Helmet>
      <div className="relative w-full max-w-screen-2xl mx-auto flex flex-col gap-6 mt-14 sm:mt-16 md:mt-14 lg:mt-16">
        <div className="flex flex-row gap-4">
          <div className="h-[490px] sm:h-[360px] md:h-[340px] lg:h-[540px] w-screen max-w-screen-2xl flex flex-shrink-0 mx-auto justify-center relative">
            <div
              className="absolute inset-0 h-full w-full bg-no-repeat bg-center object-fill"
              style={{
                backgroundImage: `url(${backgroundImg})`,
                opacity: "0.15",
              }}
            ></div>
            <div className="relative z-10 h-full w-full max-w-screen-2xl mx-auto">
              <Carousel />
            </div>
          </div>
        </div>
        <div className="w-screen block sm:hidden mx-auto flex justify-center">
          <div className="flex flex-col gap-2 justify-center mx-auto p-3">
            {!!debouncedQuery ? (         
                <SearchedEvents
                searchedEvents={searchedEvents}
                isLoading={searchLoading}
                search={debouncedQuery}
              />
                      
            ) : (
              eventsLoading ? (Array.from({ length: 25 }, (_, index) => (
                <div
                  className={`skeleton-wrapper`}
                  key={`card-${index}-${uuidv4()}`}
                >
                  <CardSkeleton />
                </div>
              )) ): allEvents.map((event, index) => (
                <EventCard key={index} event={event} />
              ))
              
            )}
          </div>
        </div>
        <div className="hidden  lg:block max-w-screen-2xl mt-[-60px] md:mb-[20px]  lg:mb-[40px]  ">
          <EventFilter
            search={search}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
        </div>
        <div className="hidden lg:block max-w-screen-2xl">
          {eventsLoading ? (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventRowSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${
                  eventsLoading ? "" : "event-row-visible"
                }`}
              >
                {!!debouncedQuery ? (
                  ""
                ) : (
                  <EventRow title="Upcoming Events" events={upcomingEvents} />
                )}
              </div>
            </div>
          )}
        </div>

        <div className={`max-w-screen-2xl hidden sm:block`}>
          {!!debouncedQuery ? "" : <EventLink />}
        </div>
        <div className="max-w-screen-2xl hidden sm:block">
          {eventsLoading ? (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventRowSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${
                  eventsLoading ? "" : "event-row-visible"
                }`}
              >
                {!!debouncedQuery ? (
                  <SearchedEvents
                    searchedEvents={searchedEvents}
                    isLoading={searchLoading}
                    search={debouncedQuery}
                  />
                ) : (
                  <EventRow title="Popular Events" events={popularEvents} />
                )}
              </div>
            </div>
          )}
        </div>

        {/* <div className=" max-w-screen-2xl hidden sm:flex">
          {eventsLoading ? (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventRowSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${eventsLoading ? "" : "event-row-visible"}`}
              >               
                 {!!search || !!allEventsSelected ? (                  
                  ""
                ) : (
                  <EventRow
                    title="This Weekend"
                    events={weekendEvents}
                  />
                )}
              </div>
            </div>
          )}
        </div> */}
        {/* <div className={`max-w-screen-2xl ${search || !!allEventsSelected ? "hidden" : "pt-[24px] pb-[40px] pl-[10px] pr-[10px] md:pl-[40px] md:pr-[40px]"}`}>
        {!!search || !!allEventsSelected ? "" : <AreYouAnEventOrganizer />}
        </div> */}
        {/* <div className=" max-w-screen-2xl">
          {eventsLoading ? (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventRowSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${eventsLoading ? "" : "event-row-visible"}`}
              >               
                 {!!search ? (                  
                  ""
                ) : (
                  <EventRow
                  title="Featured Events"
                  events={featuredEvents}
                />
                )}
              </div>
            </div>
          )}
        </div> */}
        <div
          className={`hidden sm:block max-w-screen-2xl ${
            debouncedQuery ? "hidden" : "pb-[40px]"
          }`}
        >
          {eventsLoading ? (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "" : "skeleton-hidden"
              }`}
            >
              <EventRowSkeleton />
            </div>
          ) : (
            <div
              className={`skeleton-wrapper ${
                eventsLoading ? "skeleton-hidden" : ""
              }`}
            >
              <div
                className={`event-row ${
                  eventsLoading ? "" : "event-row-visible"
                }`}
              >
                {!!debouncedQuery ? (
                  ""
                ) : (
                  <EventRow title="Music Events" events={musicEvents} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
